<template>
    <div class="video-wrapper-inner" @mousemove="[showControls($event), mousemoveHandler($event)]"
         @mouseleave="delayHideControls"
         @keyup.space="togglePlayPause" tabindex="-1"
         :class="{'controls-visible': controlsVisible}">
        <!--<template src="../templates/video-player.html"></template>-->
        <video id="video" ref="videoPlayer" playsinline
               :class="'vjs-player'"></video>

        <div>
            <div class="volume-info" v-show="volumeChanged">
                {{ volumeLevel * 10 }}% Volume
            </div>

            <div class="volume-info" v-show="currentTimeChanged">
                <br>
                <strong v-show="currentTime">
                    <span v-if="currentTime >= 3600">{{ currentTime | secondsToDateTime | dateFormat('hh:mm:ss') }}</span>
                    <span v-if="currentTime < 3600">{{ currentTime | secondsToDateTime | dateFormat('mm:ss') }}</span>
                </strong>
                <span v-if="videoDuration >= 3600">{{ videoDuration | secondsToDateTime | dateFormat('hh:mm:ss') }}</span>
                <span v-if="videoDuration < 3600">{{ videoDuration | secondsToDateTime | dateFormat('mm:ss') }}</span>
            </div>
        </div>

        <!--    <i class="player-back" @click="closeVideo()" title="Menu">-->
        <!--        <icon-menu title="Menu"></icon-menu>-->
        <!--    </i>-->

        <div v-if="controls" class="spinner" v-show="loading">
            <div class="spinner-inner">
                <icon-spinner></icon-spinner>
            </div>
        </div>

        <template v-if="!$device.mobile && controls && hasOverlaySlot">
            <transition transition name="fade" mode="out-in">
                <div class="overlay" v-show="overlayVisible" @click="play()">
                    <slot name="overlay" v-bind="{video}"></slot>
                </div>
            </transition>
        </template>

        <div v-if="!$device.mobile && controls" class="player-controls-overlay" @click="togglePlayPause"
             :class="{'has-cursor':controlsVisible}">
            <transition name="fade-out">
                <div v-show="isSight" class="player-controls-sight">
                    <icon-play v-show="playing" :class="'ion-play'"></icon-play>
                    <icon-pause v-show="!playing"></icon-pause>
                </div>
            </transition>
        </div>

        <div v-if="$device.mobile" class="player-controls-overlay mobile" @click="showControls">
            <transition name="player-button">
                <div v-if="controls" v-show="controlsVisible" class="player-controls-mobile">
                    <div v-if="video.video_chapters&&video.video_chapters.length"
                         class="player-control-button player-next-episode"
                         @click.stop="prevChapter">
                        <icon-skip-backward></icon-skip-backward>
                    </div>
                    <div class="player-control-button player-play-pause" v-show="!playing"
                         @click="play()">
                        <icon-play title="Play"></icon-play>
                    </div>
                    <div class="player-control-button player-play-pause" v-show="playing"
                         @click.stop="pause()">
                        <icon-pause title="Pause"></icon-pause>
                    </div>
                    <div v-if="video.video_chapters&&video.video_chapters.length"
                         class="player-control-button player-next-episode"
                         @click.stop="nextChapter">
                        <icon-skip-forward></icon-skip-forward>
                    </div>
                </div>
            </transition>

            <transition name="player-button">
                <div v-if="volumeMuted" class="player-controls-mobile__mute">
                <span @click.stop="playerVolumeToggle()" class="player-control-button">
                    <icon-mute></icon-mute>
        </span></div>
            </transition>
        </div>

        <!--    <transition name="player-button">-->
        <!--        <div v-if="controls && $device.mobile && !playing && !loading" class="player-controls-mobile">-->
        <!--            <div v-if="video.video_chapters&&video.video_chapters.length"-->
        <!--                 class="player-control-button player-control-button&#45;&#45;pull-right player-next-episode"-->
        <!--                 @click="prevChapter">-->
        <!--                <icon-skip-backward></icon-skip-backward>-->
        <!--            </div>-->
        <!--            <div class="play-button" @click.stop="togglePlayPause">-->
        <!--                <icon-pause v-if="playing"></icon-pause>-->
        <!--                <icon-play v-else></icon-play>-->
        <!--            </div>-->
        <!--            <div v-if="video.video_chapters&&video.video_chapters.length"-->
        <!--                 class="player-control-button player-control-button&#45;&#45;pull-right player-next-episode"-->
        <!--                 @click="nextChapter">-->
        <!--                <icon-skip-forward></icon-skip-forward>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--    </transition>-->

        <div v-if="controls && !$device.mobile" class="player-controls-wrapper player-active"
             @mousemove.stop="[mousemoveHandlerPaused($event), controlsVisible=true]" @mouseleave="mousemoveHandler($event)"
             :class="{'visible': controlsVisible}">

            <div class="player-progress-bar" v-if="currentTime&&videoDuration" v-show="!volumeOpen">

                <div class="progress-display">
                    <vue-range-slider v-if="isPlayerActive" id="playerDurationSlider" ref="playerDurationSlider" :min="0"
                                      :max="videoDuration" :real-time="true" :tooltip="'hover'"
                                      height="2" :lazy="true"
                                      :stop-propagation="true"
                                      v-model="currentTime" @slide-end="changeCurrentTime"
                                      drag-start="currentTimeChanged=true"
                                      drag-end="currentTimeChanged=false">
                    </vue-range-slider>
                </div>

                <div class="time-display">
                    <strong v-show="currentTime">
                    <span v-if="currentTime >= 3600">{{
                            currentTime | secondsToDateTime | dateFormat('hh:mm:ss')
                        }}</span>
                        <span v-if="currentTime < 3600">{{ currentTime | secondsToDateTime | dateFormat('mm:ss') }}</span>
                    </strong>
                    <span v-if="videoDuration >= 3600">{{
                            videoDuration | secondsToDateTime | dateFormat('hh:mm:ss')
                        }}</span>
                    <span v-if="videoDuration < 3600">{{ videoDuration | secondsToDateTime | dateFormat('mm:ss') }}</span>
                </div>
            </div>

            <section class="player-control-bar">

                <div class="player-control-button player-play-pause" v-show="!playing"
                     @click="play()">
                    <icon-play title="Play"></icon-play>
                </div>

                <div class="player-control-button player-play-pause" v-show="playing"
                     @click="pause()">
                    <icon-pause title="Pause"></icon-pause>
                </div>

                <!--<div class="player-control-button player-play-pause"-->
                <!--@click="rewind()">-->
                <!--<undo-icon></undo-icon>-->
                <!--</div>-->
                <!--<div class="player-control-button player-play-pause"-->
                <!--@click="forward()">-->
                <!--<redo-icon></redo-icon>-->
                <!--</div>-->

                <div class="player-control-button volume" @mouseenter="volumeOpen = true"
                     @mouseleave="volumeOpen = false">
                <span @click="playerVolumeToggle()" class="volume-control">
                    <!--<volume-off-icon ></volume-off-icon>-->
                    <icon-mute v-show="!volumeLevel||volumeMuted"></icon-mute>
                    <!--<volume-mute-icon v-show="!volumeMuted &&(volumeLevel > 0 && volumeLevel < 4)"></volume-mute-icon>-->
                    <icon-volume-mute v-show="!volumeMuted &&(volumeLevel > 0 && volumeLevel < 4)"></icon-volume-mute>
                    <!--<volume-low-icon v-show="!volumeMuted &&(volumeLevel > 3 && volumeLevel < 8)"></volume-low-icon>-->
                    <icon-volume-down v-show="!volumeMuted &&(volumeLevel > 3 && volumeLevel < 8)"></icon-volume-down>
                    <!--<volume-high-icon v-show="!volumeMuted && volumeLevel > 7"></volume-high-icon>-->
                    <icon-volume-up v-show="!volumeMuted && volumeLevel > 7"></icon-volume-up>
                </span>

                    <div class="player-menu" v-show="volumeOpen">

                        <div class="player-menu-content volume-menu-content">
                            <vue-range-slider v-if="volumeOpen" :direction="'vertical'" :height="130" :width=2 :max="10"
                                              :stopPropagation="true" :real-time="true"
                                              :tooltip="'hover'" :lazy="true" @slide-end="setVolume"
                                              v-model="volumeLevel"></vue-range-slider>
                        </div>
                    </div>
                </div>

                <div class="player-status">
                    <span v-if="video.title" class="player-status-main-title" v-html="video.title.rendered"></span>
                    <span v-if="video.video_chapters&&video.video_chapters.length">: {{
                            video.video_chapters[currentChapter].title
                        }}</span>
                </div>

                <div v-if="video.video_chapters&&video.video_chapters.length"
                     class="player-control-button player-control-button--pull-right player-next-episode"
                     @click="prevChapter">
                    <icon-skip-backward></icon-skip-backward>
                </div>
                <div v-if="video.video_chapters&&video.video_chapters.length"
                     class="player-control-button player-control-button--pull-right player-next-episode"
                     @click="nextChapter">
                    <icon-skip-forward></icon-skip-forward>
                </div>

                <div v-if="video.video_subtitles&&video.video_subtitles.length"
                     class="player-control-button player-control-button--pull-right"
                     @click="subtitleOpen=true" @mouseenter="subtitleOpen=true" @mouseleave="subtitleOpen=false">
                    <icon-text></icon-text>
                    <div class="player-menu" v-show="subtitleOpen">
                        <div class="player-menu-content caption-menu-content">
                            <h5>Subtitles</h5>
                            <ul>
                                <li v-for="subtitle in video.video_subtitles" v-if="subtitle.src"
                                    :class="{'active': currentCaption==subtitle.srclang}"
                                    :srclang="subtitle.srclang" @click="selectSubtitle(subtitle.srclang)">
                                    <md-checkmark-icon v-show="currentCaption==subtitle.srclang"></md-checkmark-icon>
                                    {{ subtitle.label ? subtitle.label : subtitle.srclang }}
                                </li>
                                <li @click="hideSubtitle()">
                                    <md-checkmark-icon v-show="!currentCaption"></md-checkmark-icon>
                                    Off
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div v-if="video.video_chapters&&video.video_chapters.length"
                     class="player-control-button player-control-button--pull-right"
                     @mouseenter="chaptersOpen=true"
                     @mouseleave="chaptersOpen=false">
                    <icon-filling></icon-filling>
                    <div class="player-menu" v-show="chaptersOpen">
                        <div class="player-menu-content episode-menu-content">
                            <h5>Chapters</h5>
                            <ul class="control-episode-list">

                                <li v-for="(chapter, key) in video.video_chapters" class="control-episode-item"
                                    :key="'episode-'+key"
                                    :class="{'active': isCurrentChapter(chapter, key), 'selected':activeChapter==key}"
                                    @click="selectChapter(chapter.start_at_second)">
                                <span v-show="activeChapter!=key" @click.stop="showChapter(key)"
                                      class="control-episode-item__title">{{ chapter.title }}</span>
                                    <span v-show="activeChapter==key"
                                          class="control-episode-item__title control-episode-item__title--active">{{
                                            chapter.title
                                        }}</span>
                                    <transition name="slide" mode="in-out">
                                        <div v-show="activeChapter==key" class="control-episode-item__body">
                                            <img v-if="chapter.thumbnail" :src="chapter.thumbnail.sizes.thumbnail"/>
                                            <span v-if="chapter.description">{{ chapter.description }}</span>
                                        </div>
                                    </transition>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

                <div class="player-control-button player-control-button--pull-right player-fill-screen"
                     @click="toggleFullScreen()"
                     :class="{'ion-arrow-shrink': isFullScreen, 'ion-arrow-expand': !isFullScreen}">
                    <icon-expand></icon-expand>
                </div>
            </section>
        </div>

    </div>
</template>
<script>
import videojs from 'video.js'
// import Youtube from 'videojs-youtube'
// import 'videojs-youtube'
// import 'imports-loader?define=>false!videojs-vimeo'
// import Vimeo from 'videojs-vimeo-2'
import {EventBus} from '../main'
import store from "../store";


const DEFAULT_EVENTS = [
    'loadeddata',
    'canplay',
    'canplaythrough',
    'play',
    'pause',
    'waiting',
    'playing',
    'ended',
    'error',
    'change',
    'useractive',
    'userinactive'
]
//full screen event?

export default {
    name: "VideoPlayer",
    props: {
        video: {
            type: Object,
            default: () => ({})
        },
        poster:String,
        autoplay: {
            type: Boolean,
            default: true
        },
        loop: Boolean,
        controls: Boolean,
        muted: {type: Boolean, default: true},
        seek: [String, Number],
        playerPause: Boolean,
        playerExit: Function,
        name: String
    },
    data() {
        return {
            subtitle: {},
            playerOptions: {
                // videojs options
                muted: true,
                language: 'en',
                poster: this.poster ||this.video.video_image,
                tracks: this.video.video_subtitles,
                controls: false,
                autoplay: this.autoplay || false,
                preload:"auto"
            },
            player: null,
            rewindTime: 10,
            controlsVisible: false,
            videoDuration: 0,
            volumeChanged: false,
            volumeOpen: false,
            volumeLevel: 1,
            volumeMuted: true,
            isMobile: false,
            currentTimeChanged: false,
            currentTime: 0,
            videoFiles: this.video.video_files,
            sources: [],
            subtitles: [],
            subtitleOpen: false,
            chaptersOpen: false,
            currentCaption: false,
            currentChapter: 0,
            activeChapter: 0,
            currentAudio: false,
            overlayVisible: false,
            overlayTimeout: 3000,
            overlayBoundary: 0,
            mousemoveBoundary: 0,
            isFullScreen: false,

            isInitialized: false,
            isNextVideoShowing: false,
            isPlayerActive: true,
            loading: true,
            playing: false,
            isSight: false,
            ended: false,
            initialPlay: true, // replace with autoplay props
            // todo remove and cleanup
            fullscreenComputed: false
        }
    },
    computed: {
        menu() {
            return store.menu
        },
        hasOverlaySlot() {
            return !!this.$slots.overlay
        }
    },
    mounted() {
        console.log("The player is mounted")

        if (this.muted === false) {
            this.playerOptions.muted = this.muted
            this.volumeMuted = this.muted
        }
        if (this.playerPause) {
            this.loading = false
        }
        this.initIsMobile()
        this.initDirective()

        if (this.seek) {
            this.selectChapter(this.seek)
        }

        this.$on('loadeddata', (payload) => {
            console.log('loadeddata', payload)
            this.videoDuration = Math.round(this.player.duration())
            this.volumeLevel = this.player.volume() * 10
            this.volumeMuted = this.player.muted()
            // console.log('volume', this.player.volume())
        })
        this.$on('canplay', (payload) => {
            console.log("Player: canplay")
            console.log(this.loading)
            console.log(payload)
            console.log("CHECK THIS")
            console.log(this.loading && payload)
            if (this.loading && payload) {
                this.$emit('canplay')
            }
            this.loading = !payload
            // if (this.autoplay)
            // this.play()
            //
        })
        this.$on('canplaythrough', (payload) => {
            console.log("Player: canplaythrough")
            //this.$emit('canplaythrough')
        })
        this.$on('change', (payload) => {
            // console.log('change', payload)
        })
        this.$on('timeupdate', (payload) => {
            // console.log(payload)
            this.ontimeupdate()
        })
        this.$on('waiting', (payload) => {
            this.loading = true
        })
        this.$on('play', (payload) => {
            this.onplay()
            // console.log('EVENT play')
        })
        this.$on('ended', (payload) => {
            if (payload)
                this.$emit('ended')
        })
        this.$on('pause', (payload) => {
            this.onpause()
        })
        this.$on('useractive', (payload) => {
            // console.log('user active')
            this.controlsVisible = true
        })
        this.$on('userinactive', (payload) => {
            // console.log('user unactive')
            // this.controlsVisible = false
        })

        this.$on('mousestop', () => {
            // console.log('mouse STOP')
            if (this.playing) {
                this.delayHideControls()
                // console.log('is it else?')
            } else {
                // if (!this.$device.mobile) {
                this.delayHideControls(3000)
                this.showOverlay()
            }
        })

        this.$on('error', (payload) => {
            console.log('ERROR')
            console.log(payload)
        })

        // EventBus.$on('playVideo', (payload) => {
        //     this.play()
        // })

        EventBus.$on('selectVideo', (payload) => {
            console.log("TEST PLAY" + this.name)
            if ('time' in payload) {
                console.log(payload.time)
                console.log(this.videoDuration)
                console.log(payload.time > this.videoDuration)
                // if (payload.time > this.videoDuration) {
                //     this.$on('loadeddata', ()=>{
                //     this.selectChapter(payload.time)
                //         console.log('more test')
                //     })
                // } else
                // setTimeout(()=>{
                //     this.selectChapter(payload.time)
                // }, 100)
                // this.player.currentTime(payload.time)
                // this.$on('canplay', ()=>{
                this.selectChapter(payload.time)
                // })
                // this.$nextTick(()=>{
                //     console.log('update time!!!')
                //     // this.player.currentTime(payload.time)
                //     this.selectChapter(payload.time)
                // })
            }
        })

        // document.addEventListener('keyup', this.eventDocEsc)

    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }

        // document.removeEventListener('keyup', this.eventDocEsc)
    },
    watch: {
        video(val, old) {
            console.log('Prop changed: ', val)
            console.log('Prop old: ', old)
            // console.log('Prop changed: ', val.video_file.url)
            if (!old || !Object.keys(old).length) {

                this.loading = true
                // this.player.pause()
                // this.player.reset()
                // this.player.tech(["youtube"])
                // this.player.tech(["vimeo"])
                // this.player.src({
                //     type: this.video.video_file.mime_type,
                //     src: this.video.video_file.url
                // })
                this.initSource()

            }

            // this.player.vimeo = false
            console.log('current source', this.player.src())
        },

        playerPause: {
            handler: 'watcherPlayerPause',
            immediate: true
        },

        muted:{
            handler: 'watcherPlayerMute'
        },

        seek(val) {
            this.selectChapter(val)
        }
    },
    activated() {
        console.log("Component videoPlayer activated")
        this.watcherPlayerPause()
    },
    filters: {},
    methods: {
        initVideojs() {
            let mv = this;
            // emit event
            const emitPlayerState = (event, value) => {
                // console.log('event',event)
                // console.log('value', value)
                if (event) {
                    mv.$emit(event, value)
                }
                // if (value) {
                //     mv.$emit('statechanged', { [event]: value })
                // }
            }
            this.player = videojs(
                this.$refs.videoPlayer,
                {...this.playerOptions, loop: this.loop || false},
                function onPlayerReady() {
                    // events
                    const events = DEFAULT_EVENTS
                    // watch events
                    const onEdEvents = {}
                    for (let i = 0; i < events.length; i++) {
                        if (typeof events[i] === 'string' && onEdEvents[events[i]] === undefined) {
                            (event => {
                                onEdEvents[event] = null
                                this.on(event, () => {
                                    emitPlayerState(event, true)
                                })
                            })(events[i])
                        }
                    }
                    // watch timeupdate
                    this.on('timeupdate', function () {
                        emitPlayerState('timeupdate', this.currentTime())
                    })
                    // todo add event 'volumechange'

                    // player readied
                    // mv.$emit('ready', true)
                    // console.log('onPlayerReady', this);
                })
            this.player.volume(0.5)
        },
        initPlayer() {
            this.player = this.$refs.videoPlayer
            this.setVolume(this.volumeLevel)
        },
        initDirective() {
            this.isInitialized = true
            // this.initPlayer()
            this.initSource()
            this.initVideojs()
        },

        initSource() {
            if (!this.video.video_file)
                return
            // this.sources = this.video.video_files.map((i) => {
            //     console.log('initSource', i)
            //     return {'type': i.video_file.mime_type, 'src': i.video_file.url}
            // });
            this.sources = [{'type': this.video.video_file.mime_type, 'src': this.video.video_file.url}]

            this.playerOptions.sources = this.sources
            if (this.player)
                this.player.src(this.sources)
        },

        watcherPlayerPause() {
            console.log('player-pause prop: ', this.playerPause)
            console.log("watcherPlayerPause")
            console.log(this.player)
            if (!this.player) {
                return
            }
            if (this.playerPause) {
                console.log('watching player: PAUSE ', this.playerPause)
                this.player.pause()
                this.overlayVisible = false
                this.controlsVisible = false
            } else {
                console.log('watching player : PLAY ', this.playerPause)
                this.play()
            }
        },

        watcherPlayerMute() {
            if (!this.player) {
                return
            }
            this.playerVolumeToggle(this.muted)
        },

        delayHideControls(timeout = 1500) {
            if (this.$device.mobile) {
                timeout = timeout * 1.5
            }
            setTimeout(() => {
                // if (this.controlsVisible == false)
                // return;

                // let now = new Date().getTime()
                // if (now - this.overlayBoundary > timeout) {
                this.$emit('userunactive')
                if (this.$device.mobile && !this.playing) {
                    //do nothing
                } else {
                    this.controlsVisible = false
                }
                // }
            }, timeout)
        },

        mousemoveHandler() {
            // console.log('mause move')
            this.clearOverlayBoundary()
            if (this.mousemoveBoundary) {
                clearTimeout(this.mousemoveBoundary);
            }
            this.mousemoveBoundary = setTimeout(() => {
                this.$emit('mousestop')
            }, 100)

        },

        mousemoveHandlerPaused() {
            // console.log('controls HOVER')
            // this.clearOverlayBoundary()
            if (this.mousemoveBoundary) {
                clearTimeout(this.mousemoveBoundary);
            }
        },

        showControls() {
            this.overlayVisible = false
            this.controlsVisible = true
        },

        showOverlay(timeout = this.overlayTimeout) {
            this.overlayBoundary = setTimeout(() => {
                if (!this.playing && this.isPlayerActive)
                    this.overlayVisible = true
            }, timeout)
        },

        clearOverlayBoundary() {
            if (this.overlayBoundary)
                clearTimeout(this.overlayBoundary)
        },

        closeVideo() {
            // console.log('close video')
            // If full screen is enabled, it will be canceled.
            if (this.isFullScreen) {
                this.toggleFullScreen()
            }
            this.pause()
            this.controlsVisible = false
            this.isPlayerActive = false
            // this.$emit('exitPlayer', {name: 'overview'})
            this.$emit('openMenu', {video: this.video.id, name: 'overview'})
            // EventBus.$emit('openMenu', {name: 'overview'})
            // this.options.onClose()
        },

        eventDocEsc(event) {
            if (event.keyCode !== 27)
                return

            if (this.controls) {
                this.pause()
            }
        },

        // toggleFullScreen() {
        //
        //     if (!this.isFullScreen) {
        //         this.isFullScreen = true
        //         if (this.$device.mobile) {
        //             this.player.enterFullWindow()
        //         } else {
        //             this.player.requestFullscreen()
        //         }
        //
        //     } else {
        //         this.isFullScreen = false
        //         if (this.$device.mobile) {
        //             this.player.exitFullWindow()
        //         } else {
        //             if (this.player.isFullscreen())
        //                 this.player.exitFullscreen()
        //         }
        //
        //     }
        // },
        toggleFullScreen() {
            var docElm
            var docElmClose = document
            if (this.$device.mobile) {
                docElm = document
                docElmClose = document.documentElement
            } else {
                docElm = document.documentElement
            }
            // var isFullScreen = window.innerHeight === screen.height
            if (!this.isFullScreen) {
                this.isFullScreen = true
                if (docElm.requestFullscreen) {
                    docElm.requestFullscreen()
                } else if (docElm.mozRequestFullScreen) {
                    docElm.mozRequestFullScreen()
                } else if (docElm.webkitRequestFullScreen) {
                    docElm.webkitRequestFullScreen()
                } else if (docElm.msRequestFullscreen) {
                    docElm.msRequestFullscreen()
                }
            } else {
                this.isFullScreen = false
                if (docElmClose.exitFullscreen) {
                    docElmClose.exitFullscreen()
                } else if (docElmClose.mozCancelFullScreen) {
                    docElmClose.mozCancelFullScreen()
                } else if (docElmClose.webkitExitFullscreen) {
                    docElmClose.webkitExitFullscreen()
                } else if (docElmClose.msExitFullscreen) {
                    docElmClose.msExitFullscreen()
                }
            }
        },

        setVolume() {
            let volume = this.volumeLevel / 10
            this.player.volume(volume)
        },

        playerVolumeToggle(val = null) {
            // console.log('volume toggle', val)
            if (val === null)
                this.volumeMuted = !this.volumeMuted
            else
                this.volumeMuted = val

            this.player.muted(this.volumeMuted)
            this.$emit('muted', this.volumeMuted)
        },

        selectSubtitle(lang) {
            let tracks = this.player.textTracks();
            console.log(tracks)
            if (lang !== this.currentCaption) {
                this.currentCaption = lang
                for (let i = 0; i < tracks.length; i++) {
                    let track = tracks[i];
                    if (track.kind === 'captions') {
                        if (track.language === this.currentCaption) {
                            track.mode = 'showing';
                        } else {
                            track.mode = 'hidden';
                        }
                    }
                }
            }
        },

        hideSubtitle() {
            let tracks = this.player.textTracks();
            this.currentCaption = false
            for (let i = 0; i < tracks.length; i++) {
                let track = tracks[i];
                if (track.kind === 'captions') {
                    track.mode = 'hidden';
                }
            }
        },

        selectAudio(lang) {
            let tracks = this.player.audioTracks();
            console.log(tracks)
            if (lang === this.currentAudio) {
                this.currentAudio = false
                for (let i = 0; i < tracks.length; i++) {
                    let track = tracks[i];
                    if (track.enabled) {
                        track.enabled = false
                    }
                }
            } else {
                this.currentAudio = lang
                for (let i = 0; i < tracks.length; i++) {
                    let track = tracks[i];
                    console.log('track.language ', track.language)
                    console.log('track.language ', this.currentAudio)
                    if (track.language === this.currentAudio) {
                        // track.enabled = true
                        this.player.audioTracks_.tracks_[i].enabled = true
                    }
                }
            }
        },

        initExternalTriggers() {
            this.$on('triggerVideoPlay', function (e, data) {
                this.play(data)
            })
            this.$on('triggerVideoPause', function (e, data) {
                this.pause(data)
            })
            this.$on('triggerVideoToggle', function (e, data) {
                if (this.playing) {
                    this.pause(data)
                } else {
                    this.play(data)
                }
            })
            this.$on('triggerVideoTimeChange', function (e, data) {
                // video.currentTime = data.currentPlayerTime
                // this.currentTime = data.currentPlayerTime
            })
        },

        onStateChangeSuccess(e, toState) {
            // if (toState.name != 'player') {
            //   // If full screen is enabled, it will be canceled.
            //   if (this.isFullScreen = true) {
            //     this.fullScreen()
            //   }
            // }
        },

        onDirectiveDestroy() {
            // Disable these shortcut keys for other pages. They are re-initialized when the user opens the player again.
            // Mousetrap.reset()

            console.log('destroy')
            // video.pause()
            // video.src = ''
            // $elem.find('video').children('source').prop('src', '')
            // $elem.find('video').remove().length = 0
        },

        ontimeupdate(event) {
            // if (isTimeScrubbingActive) {
            //   return
            // }
            this.currentTime = this.player.currentTime()
        },

        // video events
        onVideoEnded() {
            // if (this.options.showNextButton && this.options.isAutoplayNextActive) {
            //   this.options.onNext()
            // }
        },

        onerror() {
            // if (!video.duration && !this.initialPlay) {
            //   this.options.onError()
            // }
        },

        onplay() {
            // this.loading = false
            this.overlayVisible = false
            this.playing = true
            this.isPlayerActive = true
            // this.$emit('play')
        },

        onpause() {
            // this.loading = false
            this.playing = false
            this.showOverlay()

            // this.props.playerPause;
        },

        oncanplay() {
            this.loading = false
            this.videoDuration = this.player.duration
            // this.play()
            if (!this.initialPlay) {//
            }
        },

        onwaiting() {
            this.loading = true
        },
        // end video events

        toggleSelectEpisodes(episodes) {
            // this.options.selectedEpisodes = episodes
        },

        fullScreen() {
            console.log('click fullscreen', this.player.requestFullscreen())
        },

        pause() {
            console.log('click pause')
            this.player.pause()
            this.playing = false
            this.showSight()
            this.showControls()
        },

        play() {
            console.log('click play')
            console.log(this.video)
            if (this.loading)
                return false

            let promise = this.player.play()
            if (promise !== undefined) {
                console.log(promise)
                promise.then(_ => {
                    this.playing = true
                    this.isPlayerActive = true
                    this.overlayVisible = false
                    this.showSight()
                }).catch(error => {
                    // Autoplay was prevented. Show a "Play" button so that user can start playback.
                    console.log('catch Extention ', error)
                    this.$emit('playError')
                });
            } else {
                this.playing = true
                this.isPlayerActive = true
                this.overlayVisible = false
                this.showSight()
            }

        },

        rewind() {
            let rewind = this.currentTime - this.rewindTime
            if (rewind > 0) {
                this.currentTime = rewind
            } else {
                this.currentTime = 0
            }
            this.changeCurrentTime()
        },

        forward() {
            let rewind = this.currentTime + this.rewindTime
            if (rewind < this.videoDuration) {
                this.currentTime = rewind
            } else {
                this.currentTime = this.videoDuration
            }
            this.changeCurrentTime()
        },

        isCurrentChapter(chapter, key) {
            let isCurrentChapter = this.currentTime > chapter.start_at_second && this.currentTime < chapter.end_at_second
            if (!this.chaptersOpen) {
                if (isCurrentChapter) {
                    this.currentChapter = key
                    this.activeChapter = key
                }
            }
            return isCurrentChapter
        },

        showChapter(key) {
            this.activeChapter = key
        },

        selectChapter(time) {
            if (time < 0 && time > this.videoDuration)
                return
            this.currentTime = time
            this.changeCurrentTime()
            console.log('select chapter')
        },

        nextChapter() {
            console.log('nextChapter', this.currentChapter + 1 < this.video.video_chapters.length)
            if (this.currentChapter + 1 < this.video.video_chapters.length) {
                this.currentChapter += 1
                this.selectChapter(this.video.video_chapters[this.currentChapter].start_at_second)
            }
        },

        prevChapter() {
            if (this.currentChapter - 1 >= 0) {
                this.currentChapter -= 1
                this.selectChapter(this.video.video_chapters[this.currentChapter].start_at_second)
            } else {
                this.selectChapter(0)
            }
        },

        changeCurrentTime() {
            console.log('change current time', this.currentTime)
            this.player.currentTime(this.currentTime)
        },

        togglePlayPause() {
            if (this.loading)
                return false
            if (this.playing) {
                this.pause()
            } else {
                this.play()
            }
        },

        showSight() {
            console.log('show sigth')
            this.isSight = true
            setTimeout(() => {
                this.isSight = false
                console.log('timeout')
                console.log(this.isSight)
            }, 100)
        },

        changeVideoFile(videoFile, videoTime) {
            // if (!videoFile) {
            //   return
            // }
            // if (videoTime) {
            //   this.options.customStartingTime = videoTime
            // }
            // this.initialPlay = false
            // this.options.selectedVideoFile = videoFile
            // this.options.videoSrc = $sce.trustAsResourceUrl(videoFile.src || videoFile.externalLink)
            // this.options.originalFilename = videoFile.originalFilename
            // this.options.videoType = videoFile.contentType
            // localStorageService.set('selectedVideoFile', videoFile.label)
        },

        // Changes the video player's volume. Takes the changing amount as a parameter.
        changeVolume(amount) {
            this.volumeChanged = true
            // $timeout.cancel(volumeChangeTimeout)
            // // console.log('%c event', 'color: deeppink; font-weight: bold; text-shadow: 0 0 5px deeppink;', event);
            // this.volumeLevel += amount
            // // console.log('%c event', 'color: deeppink; font-weight: bold; text-shadow: 0 0 5px deeppink;', event.deltaY, this.volumeLevel);
            // this.volumeLevel = this.volumeLevel.clamp(0, 10)
            // this.$apply()
            //
            // volumeChangeTimeout = $timeout(function () {
            //   this.volumeChanged = false
            // }, 1500)
        },

        initIsMobile() {
            this.isMobile = false // initiate as false
            // device detection
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
                this.isMobile = true
            }
        },

        // Shows the video's current time and duration on the upper right corner of the screen for a limited time.
        skipActivated() {
            this.currentTimeChanged = true
            // this.options.onTimeChange(video.currentTime, this.videoDuration)
            // $timeout.cancel(currentTimeChangeTimeout)
            // this.$apply()
            //
            // currentTimeChangeTimeout = $timeout(function () {
            //   this.currentTimeChanged = false
            // }, 10000)
        },

        initMouseWheel() {
            // var isMouseWheelVolumeCtrlActive = true
            // jQuery($elem).mousewheel(function (event) {
            //   if (!isMouseWheelVolumeCtrlActive) {
            //     return
            //   }
            //   if (event.deltaY > 0) {
            //     changeVolume(1)
            //   } else if (event.deltaY < 0) {
            //     changeVolume(-1)
            //   }
            //   this.showControls()
            // })
            // var episodeSelector = document.querySelector('#player-menu-episode-selector')
            // if (episodeSelector) {
            //   episodeSelector.addEventListener('mouseenter', function (event) {
            //     isMouseWheelVolumeCtrlActive = false
            //   })
            //   episodeSelector.addEventListener('mouseleave', function (event) {
            //     isMouseWheelVolumeCtrlActive = true
            //   })
            // }
        },

        openPlaybackOptions() {
            this.pause()
            // modalService.openPlaybackOptions(this.options).then(function (response) {
            //   this.play()
            //   if (!response) {
            //     return
            //   }
            //
            //   this.options.subtitleSize = response.subtitleSize
            //   localStorageService.set('subtitleSize', response.subtitleSize)
            //
            //   if (!_.isEqualBy(response.selectedVideoFile, this.options.selectedVideoFile, 'id')) {
            //     changeVideoFile(response.selectedVideoFile, video.currentTime)
            //   }
            //   if (!_.isEqualBy(response.selectedSubtitle, this.options.selectedSubtitle, 'id')) {
            //     changeSubtitle(response.selectedSubtitle)
            //   }
            // })
        },

    }
}
</script>
